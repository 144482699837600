import VerifiedIcon from '@mui/icons-material/Verified';
import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Topic } from '../../interfaces/module';
import assets from './../../assets';

const Container = styled.div`
    background: #F8F9FA;
    min-height: '100%';
    padding: 2rem;
`;



const TopicSection = styled.div`
        flex:6;
        margin:20px;
        font-size: 24px;
        font-weight: 600;
        overflow-wrap: break-word;
    `

const TopicSelector: React.FC = () => {

    const location = useLocation();
    const { topics, className }: { topics: Topic[], className: string } = location.state || [];

    const navigate = useNavigate();

    const Section = styled.section`
        background: white;
        border-radius: 12px;
        overflow: hidden;
        border: 1px solid rgb(222, 226, 230);
        justify-content: flex-start;
        align-items: center;
        margin-bottom:16px;
        display: flex;
        align-items:stretch;

`
    const ColorBox = styled.div`
        flex:1;
        background: rgb(119, 73, 248); 
        border-top-left-radius: 4px; 
        border-top-right-radius: 4px;
`

    const TopicButtonSection = styled.div`
        
        padding: 16px 24px;
        text-align:center;
        margin:1rem;
        background: rgb(119, 73, 248);
        border-radius: 6px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        overflow-wrap: break-word;
`

    const CompletedTopic = ({ topic }: { topic: Topic }) => {
        return <Section>
            <ColorBox></ColorBox>
            <TopicSection>
                <div>
                    <span>{topic.topic}</span>
                </div>
                <div style={{ marginTop: '10px', display: 'flex', gap: '5px' }}>
                    <VerifiedIcon color='success' />
                    <span style={{ fontSize: '16px' }}>{topic.progress === 100 ? 'Completed' : "InProgress"}</span>
                </div>

            </TopicSection>

            <div style={{ alignContent: 'center' }}><TopicButtonSection>RECAPITULARE</TopicButtonSection></div>
        </Section>
    }

    const InProgressTopic = ({ topic }: { topic: Topic }) => {
        return <Section>
            <ColorBox ></ColorBox>
            <TopicSection >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>

                    <div style={{ display: 'flex', flexDirection: 'column', flex: 2, gap: '1rem' }}>
                        <div>
                            <span>{topic.topic}</span>
                        </div>
                        <div>
                            <ProgressBar
                                animateOnRender={true}
                                completed={topic.progress}
                                maxCompleted={topic.subtopics.length}
                                transitionDuration='2s'
                                bgColor='#7749F8'
                                baseBgColor='#87878730'
                                ariaValuemax={topic.subtopics.length}
                                borderRadius='3px'
                                height='21px'
                                labelAlignment='outside'
                                labelColor='black'
                                labelSize='16px'
                                customLabel={`${1}/${topic.subtopics.length}`}

                            />
                            <div style={{ fontSize: '16px', display: 'flex', justifyContent: 'center' }}>
                                <span>{topic.progress / topic.subtopics.length}%</span>
                            </div>
                        </div>


                        <div style={{ cursor: 'pointer', width: '30%', display: 'flex', textAlign: 'center', justifyContent: 'center', background: '#7749F8', borderRadius: 6, alignItems: 'flex-start', gap: '8px', padding: '8px' }}>
                            <div ><span style={{ textAlign: 'center', color: 'white', fontSize: 16, fontWeight: '600', wordWrap: 'break-word' }}>CONTINUE</span></div>
                        </div>
                    </div>


                </div>

            </TopicSection>
            <div style={{ display: 'flex', margin: '20px', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                <img alt={"progress-notes"} src={assets.images.readNotes} height={100} width={100} />
            </div>

        </Section>
    }
    const NotStartedTopic = ({ topic }: { topic: Topic }) => {
        return <Section onClick={() => navigate("/Class/PDP")}>

            <ColorBox></ColorBox>
            <TopicSection >
                <div>
                    <span>{topic.topic}</span>
                </div>
                <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>

                    <span style={{ fontSize: '18px', color: '#7749F8', fontWeight: 700 }}>{"COMPLETED"} {topic.progress} /{topic.subtopics.length}</span>
                </div>

            </TopicSection>

            <div style={{ alignContent: 'center' }}><TopicButtonSection>START</TopicButtonSection></div>
        </Section>
    }

    return (
        <Container>

            <div style={{ flex: 2 }}>
                <div style={{ flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 18, display: 'flex' }}>
                    <div style={{}}>
                        <div style={{ color: 'black', fontSize: 32, fontWeight: '600', wordWrap: 'break-word' }}>Topics</div>
                        <div style={{ color: 'black', fontSize: 16, fontWeight: '600', wordWrap: 'break-word' }}>{className}</div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '110px' }}>
                    <div style={{ flex: 3 }}>
                        {topics?.map((topic: Topic, idx: number) => (
                            topic.progress === 100 ? <CompletedTopic topic={topic} /> :
                                topic.progress === 0 ? <NotStartedTopic topic={topic} /> : <InProgressTopic topic={topic} />

                        ))}
                    </div>

                </div>
            </div>


        </Container>

    );
};

export default TopicSelector;
