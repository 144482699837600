import React, { ReactElement, useRef } from 'react';
import BookSvg from './components/BookSvg';
import CrownSvg from './components/CrownSvg';
import LampSvg from './components/LampSvg';
import PlayCircleSvg from './components/PlayCircleSvg';
import StarSvg from './components/StarSvg';
import SvgWrapper from './components/SvgWrapper';



const PDPBlock = ({ color, icon, name }: { color: string, icon?: ReactElement, name: string }) => {
    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <SvgWrapper color={color} icon={icon} />
        <span style={{
            fontSize: '20px',
            fontWeight: '700',
            textAlign: 'left',
            color: color

        }}>{name}</span>
    </div>
}

const PDP: React.FC = () => {
    const container = useRef(null);

    return (
        <div ref={container}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: '2rem' }}>
                <PDPBlock color='#FF8A00'
                    name={'Svg 5'}
                    icon={<CrownSvg />}
                />
                <PDPBlock color='#7749F8'
                    name={"Svg 1"}
                    icon={<PlayCircleSvg />}
                />

                <PDPBlock color='#7749F8'
                    name={'Svg 2'}
                    icon={<LampSvg />}
                />

                <PDPBlock color='#7749F8'
                    name={'Svg 3'}
                    icon={<StarSvg />}
                />

                <PDPBlock color='#8F00E1'
                    name={'Svg 4'}
                    icon={<BookSvg />}
                />


            </div>
        </div>

    );
};

export default PDP;
