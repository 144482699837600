import React, { useEffect, useState } from 'react';
import {
  CloseButton,
  ContentList,
  ContentSubTitle,
  ContentTile,
  FeedbackModalOverlay,
  FeedbackModalTopBar,
  FeedbackModalWrapper,
  ModalTitle
} from '../QuestionCard.styles';
import QRCode from 'react-qr-code';
import { PictureData } from '../QuestionCard';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';


const PictureAnswer = ({ handleCloseNeedHelpModal, qid, pictureData, setPictureData }: { handleCloseNeedHelpModal: () => void, qid: string, pictureData: PictureData[], setPictureData: React.Dispatch<React.SetStateAction<PictureData[]>> }) => {
  const link = `http://192.168.0.11:8081/addPictureAnswer?qid=${qid}`;
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [reset, setReset] = useState(0);

  const fetchImage = async (qid: string) => {
    try {
      const response = await fetch(`http://192.168.0.11:8081/getPictureImage?qid=${qid}`, {
        method: 'POST',
      });

      if (!response.ok) {
        setPictureData(prev => {
          prev = prev.filter((dataP, _) => dataP.id !== qid);
          return prev
        })
        setReset(prev => prev + 1)
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Get response as text (Base64 string)
      const base64String = await response.text();

      // Convert Base64 string to data URL
      const dataUrl = `data:image/jpeg;base64,${base64String}`;
      setPictureData(prev => {
        for (let i = 0; i < prev.length; i++) {
          if (prev[i].id === qid) {
            prev[i].pictureDataUrl = dataUrl
          }
        }
        return prev
      })
      setReset(prev => prev + 1)
      const canvas = document.createElement('canvas');
      canvas.width = 400;
      canvas.height = 400;

      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = dataUrl;
      img.onload = async () => {
        if (ctx) {
          const aspectRatioImage = img.width / img.height;
          const aspectRatioCanvas = canvas.width / canvas.height;

          let sx, sy, sWidth, sHeight;
          if (aspectRatioImage > aspectRatioCanvas) {
            sHeight = img.height;
            sWidth = sHeight * aspectRatioCanvas;
            sx = (img.width - sWidth) / 2;
            sy = 0;
          } else {
            sWidth = img.width;
            sHeight = sWidth / aspectRatioCanvas;
            sx = 0;
            sy = (img.height - sHeight) / 2;
          }
          ctx.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);

          const resizedDataUrl = canvas.toDataURL('image/png');
          try {
            const response = await fetch(`http://192.168.0.11:8081/readImageOut`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ image: resizedDataUrl }),
            });

            if (!response.ok) {
              setPictureData(prev => {
                prev = prev.filter((dataP, _) => dataP.id !== qid);
                return prev
              })
              setReset(prev => prev + 1)
              throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const text = await response.text();
            setPictureData(prev => {
              for (let i = 0; i < prev.length; i++) {
                if (prev[i].id === qid) {
                  prev[i].readOut = text
                }
              }
              return prev
            })
            setReset(prev => prev + 1)
          } catch (err) {
            setPictureData(prev => {
              prev = prev.filter((dataP, _) => dataP.id !== qid);
              return prev
            })
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        }
      };
    } catch (err) {
      setPictureData(prev => {
        prev = prev.filter((dataP, _) => dataP.id !== qid);
        return prev
      })
      console.error('Error fetching image:', err);
    }
  };

  return (
    <FeedbackModalOverlay>
      <FeedbackModalWrapper style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <FeedbackModalTopBar style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <ModalTitle style={{ marginRight: 'auto' }}>Manage pictures related to your answer!</ModalTitle>
          <CloseButton onClick={handleCloseNeedHelpModal}>X</CloseButton>
        </FeedbackModalTopBar>

        <ContentList style={{ margin: '1rem',width:"100%",display:"flex",flexDirection:"column",alignItems:"center" }}>
        {pictureData.map((data, index) => (
          <div key={index} style={{ display: 'flex', justifyContent: 'center', width: '98%', margin: '1%', padding: "20px", flexDirection: 'column', alignItems: 'center',backgroundColor:"#f3f4f6",borderRadius:"25px" }}>
            <div style={{ left:0,position:"relative",display:"flex",margin:"10px"}}>
              <Input variant='outlined' placeholder='Image title' style={{marginRight:"10px"}}
              defaultValue={data.title}
              onChange={(e) => {
                const value = e.target.value;
                setPictureData(prev => {
                  for (let i = 0; i < prev.length; i++) {
                    if (prev[i].id === data.id) {
                      prev[i].title = value
                    }
                  }
                  return prev
                })

              }}
              ></Input>
              <Button 
             color="neutral"
             variant="solid"
            onClick={() => {
              setPictureData(prev => {
                prev = prev.filter((dataP, _) => dataP.id !== data.id);
                return prev
              })
              setReset(prev => prev + 1)
            }}>Delete</Button>
            </div>
            
            {data.pictureDataUrl ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  src={data.pictureDataUrl}
                  style={{ width: '400px', height: '400px', objectFit: 'cover' }}
                  alt="Fetched content"
                />
             {data.readOut == "" ? <p>Processing your image....</p> :
                <p style={{ marginTop: '20px' }}>{data.readOut}</p>
              }

              </div>


            ) : (
              <>
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '20%', width: 'auto' }}
                value={`http://192.168.0.11:8081/addPictureAnswer?qid=${data.id}`}
                viewBox="0 0 256 256"
              />

              </>
            )}

            
          </div>

        ))}
         <Button onClick={() => {
          console.log("Add new picture!");
          const myId = qid + Math.random().toString();
          setPictureData(prev => {
            prev.push({ id: myId, pictureDataUrl: "", readOut: "",title:"" });
            console.log(prev);
            return prev;
          });
          setReset(reset + 1);
          fetchImage(myId);
        }} style={{ marginTop: '20px',marginBottom:"20px",alignSelf:"center" }}>Add new picture!</Button>
       
         </ContentList>
        
      </FeedbackModalWrapper>
    </FeedbackModalOverlay>
  );
};

export default PictureAnswer;
