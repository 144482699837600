
import { Question } from '../../../interfaces/Question';
import {
    CloseButton,
    ContentList,
    ContentSubTitle,
    ContentTile,
    FeedbackModalOverlay,
    FeedbackModalTopBar,
    FeedbackModalWrapper,
    ModalTitle,
    VideoClipPlaceholder
} from '../QuestionCard.styles';
import 'katex/dist/katex.min.css';
import Latex from 'react-latex-next';


const HelpOverlay = ({ question, helpVideoUrl, handleCloseNeedHelpModal }: { helpVideoUrl:any, question: Question, handleCloseNeedHelpModal: () => void }) => {
  

    return (
        <FeedbackModalOverlay>
<link
        href="//cdnjs.cloudflare.com/ajax/libs/KaTeX/0.11.1/katex.min.css"
        rel="stylesheet"
    />
        <FeedbackModalWrapper>

          <FeedbackModalTopBar style={{ alignItems: 'center' }}>
            <ModalTitle>The below video will help you understand the concepts ...</ModalTitle>
            <CloseButton onClick={handleCloseNeedHelpModal}>X</CloseButton>
          </FeedbackModalTopBar>
          {helpVideoUrl?.url && <VideoClipPlaceholder>
            <video
              controls
              style={{ width: '100%', height: '100%', }}
            >
              <source src={helpVideoUrl.url} type="video/mp4" />
            </video>
          </VideoClipPlaceholder>}

          <ContentList style={{ margin: '1rem' }}>
            <ContentTile>Sample Answer:</ContentTile>
            <ContentSubTitle>{question.sampleAnswer}</ContentSubTitle>
          </ContentList>
        </FeedbackModalWrapper>

      </FeedbackModalOverlay>
    )
}

export default HelpOverlay
