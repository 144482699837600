
const LampSvg = () => {
    return (
        <svg>
            <path xmlns="http://www.w3.org/2000/svg" opacity="0.66" d="M64.5 63.3574C63.1655 62.5696 62.1225 61.3534 61.5328 59.8976C60.9432 58.4417 60.8397 56.8276 61.2385 55.3055C61.6373 53.7835 62.5162 52.4385 63.7387 51.4792C64.9612 50.52 66.4591 50 68 50C69.5409 50 71.0388 50.52 72.2613 51.4792C73.4838 52.4385 74.3627 53.7835 74.7615 55.3055C75.1603 56.8276 75.0568 58.4417 74.4672 59.8976C73.8775 61.3534 72.8345 62.5696 71.5 63.3574V75.0522H64.5V63.3574ZM54 76.8418H82C82.9283 76.8418 83.8185 77.2189 84.4749 77.8901C85.1312 78.5613 85.5 79.4716 85.5 80.4209V84H50.5V80.4209C50.5 79.4716 50.8687 78.5613 51.5251 77.8901C52.1815 77.2189 53.0717 76.8418 54 76.8418Z" fill="white" fill-opacity="0.81" />
            <path xmlns="http://www.w3.org/2000/svg" d="M66.0001 66.3696C65.2375 65.9294 64.6415 65.2498 64.3045 64.4363C63.9676 63.6227 63.9085 62.7208 64.1364 61.8702C64.3643 61.0197 64.8664 60.2681 65.565 59.7321C66.2636 59.1961 67.1195 58.9055 68.0001 58.9055C68.8806 58.9055 69.7365 59.1961 70.4351 59.7321C71.1337 60.2681 71.6359 61.0197 71.8638 61.8702C72.0917 62.7208 72.0325 63.6227 71.6956 64.4363C71.3586 65.2498 70.7626 65.9294 70.0001 66.3696V72.9046H66.0001V66.3696ZM60.0001 73.9046H76.0001C76.5305 73.9046 77.0392 74.1153 77.4143 74.4904C77.7893 74.8655 78.0001 75.3742 78.0001 75.9046V77.9046H58.0001V75.9046C58.0001 75.3742 58.2108 74.8655 58.5858 74.4904C58.9609 74.1153 59.4696 73.9046 60.0001 73.9046Z" fill="white" />
        </svg>
    )
}

export default LampSvg
